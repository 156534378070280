<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" :clipped="true" :absolute="false" app style="border-right: 1px solid">
      <v-list dense>
        <v-list-item-group v-model="menu">
          <v-list-item link :to="{ name: 'clients' }">
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>CLIENTS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'bs' }">
            <v-list-item-action>
              <v-icon>mdi-hair-dryer-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>BEAUTY SPOTS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'bsFinance' }">
            <v-list-item-action>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>BS FINANCEIRO</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item link :to="{ name: 'bmFinance' }">
            <v-list-item-action>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>BM FINANCEIRO</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'appUsers' }">
            <v-list-item-action>
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>USERS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'benefits' }">
            <v-list-item-action>
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>BENEFÍCIOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-item link :to="{ name: 'benefits' }">-->
          <!--            <v-list-item-action>-->
          <!--              <v-icon>mdi-cash-multiple</v-icon>-->
          <!--            </v-list-item-action>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>PAGAMENTOS</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->




          <v-divider></v-divider>

          <v-list-item link :to="{ name: 'logout' }">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>SAIR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="#3D1159" dark :clipped-left="true" app :absolute="false">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-img class="mx-1" src="@/assets/lyndus_logo.png" max-height="50" max-width="200" contain></v-img>

      <v-toolbar-title class="black--text"></v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on">
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ currentUser }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item :key="currentUser" link :to="{ name: 'logout' }">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <span>
        <v-icon class="mr-2">mdi-account</v-icon>
        {{ currentUser }}
      </span>
    </v-app-bar>

    <v-main>
      <v-container fill-height fluid>
        <v-layout>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.v-system-bar a {
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  font-size: 10px;
  color: #82d0f5;
  margin: 0px 0px 10px;
  text-decoration: none;
  line-height: 10px;

  float: left;
  display: block;
  height: 8px;
  margin-top: 13px;
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid #0078b4;
}

.v-system-bar .last {
  border-right: none;
}

.v-system-bar .on {
  color: #ffffff;
}

.v-footer a {
  font-family: "SourceSansPro";
  font-size: 12px;
  color: #ffffff;
  line-height: 15px;
  text-decoration: none;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  props: {
    source: String
  },

  // async beforeRouteUpdate(to, from, next) {
  //   // Reset state if user goes from /editor/:id to /editor
  //   // The component is not recreated so we use to hook to reset the state.
  //   await store.commit(CLIENTS_RESET);
  //   return next();
  // },
  // async beforeRouteEnter(to, from, next) {
  //   Promise.all([
  //     store.commit(CLIENTS_RESET),
  //     store.dispatch(CLIENTS_GET)
  //   ]).then(() => {
  //     next();
  //   });
  // },

  data: () => ({
    isLoading: false,
    drawer: true,
    menu: 4
  }),

  watch: {},

  methods: {
    getCurrentUser() {
      this.$store.state.user;
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push('/clients')
    } else {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapGetters(["userData", "currentUser", "isAuthenticated"])
  }
};
</script>
